import React, { createContext } from 'react'
import { ENV, SERVER_HOST, PRODUCTION } from './config';
import { REST_CONNECTION_STATUS_DETAILS_VERIFIED, REST_CONNECTION_STATUS_DETAILS_FAILED, REST_CONNECTION_STATUS_DETAILS_UNCHECKED } from '../store/state';
import store from "../store/store";
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import { PAYMENT_INTENT_PATH, PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_INITIATE_REQUESTED, RETRIEVE_ALL_TERMINALS_PATH, TERMINAL_SESSION_PATH, createAutoPaymentIntentRequest, createCapturePaymentIntentRequest, createManualPaymentIntentRequest, createMatchedRefundTerminalSessionRequest, createSaleTerminalSessionRequest, createUpdatePaymentIntentAmountRequest, handleAutoSaleTerminalSessionResponse, handleGetTerminalSessionResponse, handleManualSaleTerminalSessionResponse } from './omniApi';
import { restRequestCreated, restRequestFinished } from "../store/actions";

const METHOD_GET = "get";
const METHOD_POST = "post";
const METHOD_PUT = "put";

const RestContext = createContext(null);

export { RestContext }

export default ({ children }) => {
  let rest;
  let config;

  async function checkAccountDetails(apiKey, useEnv, setConnectionStatus) {
    try {
      config = {
        apiKey: apiKey,
        useEnv: useEnv
      };
      const response = await sendGetRequestToBackend(RETRIEVE_ALL_TERMINALS_PATH);
      if (response.status === 200) {
        setConnectionStatus(REST_CONNECTION_STATUS_DETAILS_VERIFIED)
        if (ENV !== PRODUCTION) {
          console.log("Confirmed valid account connection through REST:", { apiKey: apiKey });
        }
      }
    }
    catch (error) {
      if (error.status === 401) {
        setConnectionStatus(REST_CONNECTION_STATUS_DETAILS_FAILED)
      } else {
        setConnectionStatus(REST_CONNECTION_STATUS_DETAILS_UNCHECKED)
      }
    }
  }

  async function sendGetTerminalsRequest(modals, setModals) {
    try {
      const response = await sendGetRequestToBackend(RETRIEVE_ALL_TERMINALS_PATH);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received valid Get Terminals Response");
        }
        modals.pdqView.pdqs = [];
        response.data.data.forEach(terminal => {
          modals.pdqView.pdqs.push({ terminalId: terminal.id, tid: terminal.properties.tid });
        });
        modals.tabsView.isVisible = false;
        modals.pdqView.isVisible = true;
        setModals({ ...modals });
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function sendCapturePaymentIntentRequest(modals, setModals, balance, paymentIntentId) {
    try {
      const data = createCapturePaymentIntentRequest(balance);
      const response = await sendGenericRequestToBackend(`${PAYMENT_INTENT_PATH}/${paymentIntentId}/captures`, METHOD_POST, data);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received valid Capture Payment Intent Response");
        }
        const tab = getTxOrTab(modals, paymentIntentId);
        tab.status = PAYMENT_STATUS_CAPTURED;
        setModals({...modals});
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function sendUpdatePaymentIntentAmountRequest(modals, setModals, balance, paymentIntentId, currency) {
    try {
      const data = createUpdatePaymentIntentAmountRequest(balance, currency);
      const response = await sendGenericRequestToBackend(`${PAYMENT_INTENT_PATH}/${paymentIntentId}/amount`, METHOD_POST, data);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received valid Capture Payment Intent Response");
        }
        const tab = getTxOrTab(modals, paymentIntentId);
        tab.amountHeld = tab.balance;
        setModals({...modals});
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function sendCancelTerminalSessionRequest(terminalSessionId) {
    try {
      const response = await sendGenericRequestToBackend(`${TERMINAL_SESSION_PATH}/${terminalSessionId}/cancel`, METHOD_PUT);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received valid Cancel Terminal Session Response");
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function sendCreateAutoPaymentIntentRequest(modals, setModals, terminalId, balance, items, currency) {
    try {
      const data = createAutoPaymentIntentRequest(balance, currency);
      const response = await sendGenericRequestToBackend(PAYMENT_INTENT_PATH, METHOD_POST, data);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received valid Create Payment Intent (Auto) Response");
        }
        const paymentIntentId = response.data.data.id;
        await sendCreateSaleTerminalSession(modals, setModals, terminalId, paymentIntentId, balance, items,handleAutoSaleTerminalSessionResponse)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function sendCreateManualPaymentIntentRequest(modals, setModals, terminalId, balance, items, currency) {
    try {
      const data = createManualPaymentIntentRequest(balance, currency);

      const response = await sendGenericRequestToBackend(PAYMENT_INTENT_PATH, METHOD_POST, data);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received valid Create Payment Intent (Manual) Response");
        }
        const paymentIntentId = response.data.data.id;
        await sendCreateSaleTerminalSession(modals, setModals, terminalId, paymentIntentId, balance, items, handleManualSaleTerminalSessionResponse)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function sendCreateSaleTerminalSession(modals, setModals, terminalId, paymentIntentId, balance, items, handlePaymentIntentResponse) {
    try {
      const data = createSaleTerminalSessionRequest(terminalId, paymentIntentId);
      const response = await sendGenericRequestToBackend(TERMINAL_SESSION_PATH, METHOD_POST, data);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received Create Terminal Session (Sale) Response");
        }
        await handlePaymentIntentResponse(response.data.data, modals, setModals, balance, paymentIntentId, items);
        const txOrTab = getTxOrTab(modals, paymentIntentId);
        await pollGetTerminalSessionRequest(modals, setModals, response.data.data.id, txOrTab)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function sendCreateMatchedRefundTerminalSession(modals, setModals, paymentIntentId, terminalId, balance, currency) {
    try {
      const data = createMatchedRefundTerminalSessionRequest(terminalId, paymentIntentId, balance, currency);
      const response = await sendGenericRequestToBackend(TERMINAL_SESSION_PATH, METHOD_POST, data);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          console.log("Received Create Terminal Session (Matched Refund) Response");
        }
        const txOrTab = getTxOrTab(modals, paymentIntentId)
        txOrTab.status = PAYMENT_STATUS_INITIATE_REQUESTED;
        await pollGetTerminalSessionRequest(modals, setModals, response.data.data.id, txOrTab)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function pollGetTerminalSessionRequest(modals, setModals, terminalSessionId, txOrTab) {
    try {
      const response = await sendGetRequestToBackend(`${TERMINAL_SESSION_PATH}/${terminalSessionId}`);
      if (response.status === 200) {
        if (ENV !== PRODUCTION) {
          const res = response.data.data.notificationEvents.length > 0 ? response.data.data.notificationEvents[response.data.data.notificationEvents.length -1].notificationType : response.data.data.status
          console.log(`Received Get Terminal Session Response [${res}]`);
        }

        const isAtTerminalStatus = await handleGetTerminalSessionResponse(response.data.data, modals, setModals, txOrTab);

        if (isAtTerminalStatus === false) {
          await sleep(pollGetTerminalSessionRequest, modals, setModals, terminalSessionId, txOrTab);
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  function getTxOrTab(modals, paymentIntentId) {
    const txs = modals.transactionsView.transactions;
    const tabs = modals.tabsView.tabs;
    const foundTxIndex = txs.findIndex(tx => tx.pid === (paymentIntentId));
    const foundTabIndex = tabs.findIndex(tb => tb.pid === (paymentIntentId));
    modals.transactionsView.isVisible = foundTxIndex !== -1 ? true : false;
    return foundTxIndex !== -1 ? txs[foundTxIndex] : foundTabIndex !== -1 ? tabs[foundTabIndex] : -1;
  }

  async function sendGetRequestToBackend(path) {
    const id = uuidv4();

    store.dispatch(
      restRequestCreated(
        id,
        METHOD_GET,
        path,
        {
          Authorization: `Basic ${config.apiKey}`,
        },
        null
      )
    );

    return axios.post(`${SERVER_HOST}/send`, undefined, {
      headers: {
        apiKey: config.apiKey,
        useagclient: config.useAGClient,
        useenv: config.useEnv,
        method: METHOD_GET,
        path: path
      }
    }).then((res) => {
      store.dispatch(restRequestFinished(id, res));
      return res;
    });
  }

  async function sendGenericRequestToBackend(path, method, data) {
    const id = uuidv4();
    store.dispatch(
      restRequestCreated(
        id,
        method,
        path,
        {
          Authorization: `Basic ${config.apiKey}`,
        },
        data
      )
    );

    return axios.post(`${SERVER_HOST}/send`, data, {
      headers: {
        apiKey: config.apiKey,
        useagclient: config.useAGClient,
        useenv: config.useEnv,
        method: method,
        path: path
      }
    }).then((res) => {
      store.dispatch(restRequestFinished(id, res));
      return res;
    });
  }

  async function sleep(fn, ...args) {
    await timeout(1000);
    return fn(...args);
  }

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  rest = {
    checkAccountDetails,
    sendGetTerminalsRequest,
    sendCapturePaymentIntentRequest,
    sendCancelTerminalSessionRequest,
    sendCreateAutoPaymentIntentRequest,
    sendCreateMatchedRefundTerminalSession,
    sendCreateManualPaymentIntentRequest,
    sendUpdatePaymentIntentAmountRequest
  }

  return (
    <RestContext.Provider value={rest}>
      {children}
    </RestContext.Provider>
  )
}
